import createImmutableSelector from 'utils/immutable-selector';
import { matchPath } from 'react-router-dom';

import routes from 'components/App/routes';
import { currentProjectIdSelector } from 'modules/user/selectors';
import { commuteOffersServiceDateSelector } from 'modules/ui/selectors';

const routerSelector = state => state.get('router');

export const locationSelector = createImmutableSelector(
  routerSelector,
  router => router.get('location')
);

export const routePageAddressSelector = createImmutableSelector(
  locationSelector,
  currentProjectIdSelector,
  commuteOffersServiceDateSelector,
  (location, currentProjectId, currentServiceDate) => {
    return ({
      page = location,
      projectId = currentProjectId,
      serviceDate = currentServiceDate,
      previousPage = '',
    }) => {
      return [
        page,
        [
          `project-id=${projectId}`,
          `simulation-date=${serviceDate}`,
          previousPage && `previousPage=${previousPage}`,
        ]
          .filter(Boolean)
          .join('&'),
      ].join('?');
    };
  }
);

export const routeParamsSelector = createImmutableSelector(
  locationSelector,
  (location) => {
    if (!location) return {};

    const activeRoute =
      routes.find(route => matchPath(location.get('pathname'), route)) || {};

    const match = matchPath(location.get('pathname'), activeRoute);
    return match.params;
  }
);

export const routeIdSelector = createImmutableSelector(
  routeParamsSelector,
  params => params.id && parseInt(params.id, 10)
);

export const routePageSelector = createImmutableSelector(
  routeParamsSelector,
  params => (params.page && parseInt(params.page, 10)) || 1
);
