import React from 'utils/react';
import md5 from 'blueimp-md5';

import Dropdown from 'components/Dropdown';

import Container from './Container';
import Content from './Content';
import Item from './Item';
import ProjectLabel from './ProjectLabel';
import { LOGISTICS_SERVICES_ROUTE } from 'utils/constants';

const ProjectMenu = (props) => {
  const {
    currentProject,
    projects,
    serviceDate,
    changeCurrentProject,
    isProjectSelectionDisabled,
    projectLabelTarget,
    logisticPageSelector,
  } = props;

  const currentProjectName = currentProject
    ? currentProject.get('name')
    : 'loading...';

  return (
    <React.Fragment>
      {isProjectSelectionDisabled && (
        <ProjectLabel to={projectLabelTarget}>
          {currentProjectName}
        </ProjectLabel>
      )}
      {!isProjectSelectionDisabled && (
        <Dropdown
          data-testid='ProjectMenu-Dropdown'
          renderHandler={({ toggle, toggledOn }) => (
            <Container
              isActive={toggledOn}
              onClick={toggle}
              data-testid='ProjectMenu-Container'
              data-testhash={`ProjectMenu-${md5(currentProjectName)}`}
            >
              {currentProjectName}
            </Container>
          )}
          renderContent={({ toggle }) => (
            <Content onClick={toggle} data-testid='ProjectMenu-Content'>
              {projects.map((project) => {
                const onClick = () => {
                  const nextPage = logisticPageSelector({
                    page: LOGISTICS_SERVICES_ROUTE,
                    projectId: project.get('id'),
                  });
                  changeCurrentProject(project, currentProject, nextPage);
                };

                return (
                  <Item
                    key={project ? project.get('id') : '0'}
                    data-testid={`project-id-${
                      project ? project.get('id') : '0'
                    }`}
                    data-testhash={`project-hash-${md5(
                      project ? project.get('name') : ''
                    )}`}
                    onClick={onClick}
                  >
                    {project ? project.get('name') : '--'}
                  </Item>
                );
              })}
            </Content>
          )}
        />
      )}
    </React.Fragment>
  );
};

export default ProjectMenu;
